import React, {Component} from "react";
import {sobre, like, pc, conexion, rocket} from './dataiconos'

class Feature extends Component {
    render() {
        let data = [

            {
                icon: pc,
                duration:1000,
                class: "zoom-out-left",
                title: 'Centraliza y digitaliza',
                desc: 'El proceso de descubrimiento e incorporación de soluciones tecnológicas disruptivas.'
            },

            {
                icon: rocket,
                duration:1500,
                class: "zoom-out-left",
                title: 'Busca y filtra',
                desc: 'Navega por la plataforma en búsqueda de soluciones que se adecuen a tus necesidades usando filtros.'
            },

            {
                icon: conexion,
                duration:2000,
                class: "zoom-out-left",
                title: 'Vincúlate',
                desc: 'Con el ecosistema de innovación de Latam en forma autónoma.'
            },

            {
                icon: like,
                duration:2500,
                class: "zoom-out-left",
                title: 'Involucra a los colaboradores',
                desc: 'de las distintas áreas en el proceso de descubrimiento, selección y fitting.'
            },

            {
                icon: sobre,
                duration:3000,
                class: "zoom-out-left",
                title: 'Conecta e interactúa',
                desc: 'Comunicaciones directas con otros innovadores del ecosistema.'
            }
        ]

        let DataList = data.map((val, i) => {
            return (
                /* Start Single Feature */
                <div className="feature" key={i}  data-aos={val.class}  data-aos-duration={val.duration}>
                    <div className="feature-icon">
                        <img src={val.icon} height={60} width={65} style={{marginTop:'17%'}} alt="iconos"/>
                    </div>
                    <div className="content">
                        <h4 className="title">{val.title}</h4>
                        <p className="desc">{val.desc}</p>
                    </div>
                </div>
                /* End Single Feature */
            )
        })

        return (
            <div className={`feature-area feature-bg-image  ${this.props.horizontalfeature}`} id="features"
                 data-aos-duration="4000"
                 data-aos-easing="ease-in-out">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb-lg-5 mb-sm-4" data-aos="zoom-out-up" data-aos-delay="50"
                                 data-aos-duration="1000">
                                <h2 className="theme-color">ACELERA EL CORPORATE VENTURING<br/>
                                    <span style={{color: 'black'}}>EN UN ECOSISTEMA DIGITAL</span></h2>
                                {/*<img className="image-1" src={require('../assets/images/app/title-icon.png').default} alt="App Landing"/>*/}
                                {/*<img className="image-2" src={require('../assets/images/app/title-icon-2.png').default} alt="App Landing"/>*/}
                                {/*<img className="image-3" src={require('../assets/images/app/title-icon-3.png').default} alt="App Landing"/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row mt--100" >
                        <div className="col-lg-7 offset-lg-5">
                            <div className="feature-list">
                                {DataList}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Feature;
