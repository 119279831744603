import './index.scss';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
    <React.Fragment >
        {/*<I18nextProvider i18n={i18next}>*/}
            <App/>
        {/*</I18nextProvider>*/}
    </React.Fragment>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

