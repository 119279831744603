import React, {useEffect} from "react";
import Footer from '../pantallas/Footer/Footer';
import {Link, useParams} from "react-router-dom";
import {bci, comfenalco, sura} from "../pantallas/Casos_exito/data";
import imagen from '../assets/images/logo/Logo-InnovaConnect-blanco.png'
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {fetchEstadoFalse} from "../contexts/Redux/reducers/login";
import {Modal, ModalBody} from "reactstrap";
import Login from "../pantallas/Login/login";
import RegistrarCuenta from "../pantallas/Registrar_cuenta/registrarCuenta";

const BlogDetails = (props) => {
    const {id} = useParams();
    const [modal, setmodal] = React.useState(false);
    const [idmodal, setidmodal] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [token] = React.useState(localStorage.getItem('token_2'));
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const {estadoLogin} = useSelector(state => state.estadoLogin);

    const dispatch = useDispatch()

    useEffect(() => {
        window.scroll(0, 0);
    }, [])

    const handlePlataforma = () => {

    }

    function handleCerrarSesion() {
        localStorage.removeItem('token');
        localStorage.removeItem('estadoLogin')
        localStorage.clear();
        dispatch(fetchEstadoFalse())
        setAnchorElUser(null);
    }

    function handdleModal(value) {
        setmodal(!modal)
        setidmodal(value)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    let data = [
        {
            image: sura,
            class: "fade-right",
            title: 'Seguros Sura, automatización y digitalización: las claves para potenciar la innovación',
            link: '/casosexito',
            parrafo: 'Inspirados en mejorar la gestión de sus procesos y el relacionamiento con sus proveedores de asistencia, es que Sura se une a Innova360 para revolucionar la innovación dentro de su organización. \n' +
                '\n' +
                'Buscando la forma de automatizar y digitalizar el proceso de pago de proveedores de servicios de asistencia, involucrando la información de sus clientes y el flujo de entrega de sus servicios, es que desarrolla un programa de innovación abierta utilizando la plataforma InnovaConnect, ecosistema digital que reúne soluciones tecnológicas que resuelven desafíos de grandes empresas a nivel de Latam.\n' +
                '\n' +
                'En solo 12 semanas se conocieron 19 soluciones, de las cuales 8 fueron preseleccionadas por el equipo de Innova360 quienes seleccionaron 4 para llegar a un DemoDay con los ejecutivos de Sura, quienes definieron a NearWay para la ejecución de un piloto.\n' +
                '\n' +
                'Clemente Canales, Gerente General de la startup destaca que “el proceso para ingresar la idea a la página es sencillo y rápido. El equipo de Innova360 nos acompañó en todo el camino, entregando feedback en cada instancia, lo que nos permitió afinar los detalles para la presentación final. Todo fue muy ágil y profesional, logrando armar una propuesta que contempla nuestra propuesta de valor y las necesidades de Sura”.\n' +
                '\n' +
                'Para Andres Peña Cerda , Jefe de experiencia de clientes “gracias a Innova360, canalizamos nuestro desafío y llegamos a empresas capaces de entender nuestras problemáticas. Con un análisis en profundidad, de manera ágil y con tiempos de respuesta muy acotados, lograron llegar a nosotros con soluciones de diversas startups con demostraciones y propuestas concretas lo que nos ayudó a avanzar en nuestro objetivo de mejorar nuestra propuesta de valor para nuestros clientes”.\n'
        },
        {
            image: bci,
            class: "fade-up",
            title: 'InnovaConnect + Bci: Innovación Abierta para la digitalización de procesos de entrega de productos bancarios',
            link: '/casosexito',
            parrafo: 'Desde el año 2019 Banco Bci ha trabajado con distintos actores del ecosistema para fomentar espacios colaborativos con foco en emprendimiento e innovación a través de la implementación de distintos programas, incluyendo la innovación abierta dentro de su estrategia.. En su afán de brindar la mejor experiencia en el proceso de entrega de productos bancarios para sus clientes es que lanza una convocatoria a través de InnovaConnect, para encontrar soluciones tecnológicas que fueran ágiles, intuitivas y que permitieran integrar la planificación y seguimiento de las actividades que requiere este proceso. \n' +
                '\n' +
                'A través de nuestra plataforma de gestión de Innovación Abierta, InnovaConnect, en solo dos meses nuestro equipo  y las áreas de: Operaciones banco retail, Compras, Transformación y Autorización de Procesos y Desarrollo y Mantención Retail de Banco Bci, logramos realizar un proceso ágil, dinámico y con resultados concretos.\n' +
                '\n' +
                'María Marcela Sabucedo, Gerente de Compras Coporativas de Bci, comenta “Nuestra experiencia con Innova360 ha sido sumamente satisfactoria, han demostrado ser un partner estratégico en todas las etapas del proceso, le dieron vuelco y transformación a lo planteado inicialmente. Su plataforma nos permitió conectarnos con startups que daban verdadera solución al desafío planteado”.\n' +
                'Entre más de 34 soluciones postuladas, de las cuales 15 fueron preseleccionadas a DemoDay, 6 fueron clasificadas para una evaluación técnico-económica resultando 1 ganador para la ejecución de un piloto. SimpleData con su solución DFEED genera una experiencia única y 100% digital entre el cliente, el operador de logística, delivery y servicios, permitiendo la trazabilidad completa del proceso de punta a punta. Para Adiel Infante, CEO de Simple Data fue “una experiencia enriquecedora, Innova360 ha sido un partner muy eficiente en su acompañamiento, orientación, rapidez y planificación. Creemos que es un verdadero HUB de negocios, muy importante para potenciar las áreas comerciales de las empresas que recién están comenzando”.\n'
        },
        {
            image: comfenalco,
            class: "fade-left",
            title: 'AInnovaConnect + Bci: Innovación Abierta para la digitalización de procesos de entrega de productos bancarios',
            link: '/casosexito',
            parrafo: 'Desde el año 2019 Banco Bci ha trabajado con distintos actores del ecosistema para fomentar espacios colaborativos con foco en emprendimiento e innovación a través de la implementación de distintos programas, incluyendo la innovación abierta dentro de su estrategia.. En su afán de brindar la mejor experiencia en el proceso de entrega de productos bancarios para sus clientes es que lanza una convocatoria a través de InnovaConnect, para encontrar soluciones tecnológicas que fueran ágiles, intuitivas y que permitieran integrar la planificación y seguimiento de las actividades que requiere este proceso. \n' +
                '\n' +
                'A través de nuestra plataforma de gestión de Innovación Abierta, InnovaConnect, en solo dos meses nuestro equipo  y las áreas de: Operaciones banco retail, Compras, Transformación y Autorización de Procesos y Desarrollo y Mantención Retail de Banco Bci, logramos realizar un proceso ágil, dinámico y con resultados concretos.\n' +
                '\n' +
                'María Marcela Sabucedo, Gerente de Compras Coporativas de Bci, comenta “Nuestra experiencia con Innova360 ha sido sumamente satisfactoria, han demostrado ser un partner estratégico en todas las etapas del proceso, le dieron vuelco y transformación a lo planteado inicialmente. Su plataforma nos permitió conectarnos con startups que daban verdadera solución al desafío planteado”.\n' +
                'Entre más de 34 soluciones postuladas, de las cuales 15 fueron preseleccionadas a DemoDay, 6 fueron clasificadas para una evaluación técnico-económica resultando 1 ganador para la ejecución de un piloto. SimpleData con su solución DFEED genera una experiencia única y 100% digital entre el cliente, el operador de logística, delivery y servicios, permitiendo la trazabilidad completa del proceso de punta a punta. Para Adiel Infante, CEO de Simple Data fue “una experiencia enriquecedora, Innova360 ha sido un partner muy eficiente en su acompañamiento, orientación, rapidez y planificación. Creemos que es un verdadero HUB de negocios, muy importante para potenciar las áreas comerciales de las empresas que recién están comenzando”.\n'
        },
    ];

    return (
        <div className=" app-header sticker" id="main-menu">
            <div
                style={{background: '#424242', padding: '10px', alignSelf: 'center'}}>
                <div className="row">
                    <div className="col-lg-9 col-sm-8 col-7" style={{alignSelf: 'center'}}>
                        <div className="logo" style={{margin: '20px'}}>
                            <img className="logo-1" id="styleLogo"
                                 src={imagen}
                                 alt="app landing"/>
                            {/*<img className="logo-2" src={require('../assets/images/logo/Logo-InnovaConnect-blanco.png').default} alt="app landing"/>*/}
                            {/*<img className="logo-3" src={require('../assets/images/logo/Logo-InnovaConnect-blanco.png').default} alt="app landing"/>*/}

                        </div>
                    </div>
                    {/*<div className="col-lg-7 col-sm-5  col-4 "  style={{textAlign: 'end'}}>*/}
                    {/*    <div style={{marginTop: '47px'}}>*/}
                    {/*        <div className="mainmenu-wrapper">*/}
                    {/*            <nav>*/}
                    {/*                <ul className="main-menu">*/}
                    {/*                    <li className="active">*/}
                    {/*                        <a href="/" style={{color:'#ffff',fontSize:'18px', fontWeight:600}}>Home</a></li>*/}
                    {/*                </ul>*/}
                    {/*            </nav>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-lg-3 col-sm-4  col-5 " style={{alignSelf: 'center', textAlign: 'center'}}>
                        {estadoLogin ?
                            <div style={{marginRight: '35px'}}>
                                <IconButton onClick={handleToggle}
                                            ref={anchorRef}
                                            id="composition-button"
                                            aria-controls={open ? 'composition-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup="true"
                                >
                                    <Avatar sx={{background: '#fd601c', width: 50, height: 50}}
                                            alt="Camila Carrasco"
                                            src="/static/images/">CC </Avatar>
                                </IconButton>


                                <Popper
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    placement="bottom-start"
                                    transition
                                    disablePortal
                                >
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="composition-menu"
                                                        aria-labelledby="composition-button"
                                                        onKeyDown={handleListKeyDown}
                                                    >

                                                        <MenuItem onClick={handleClose}><Link
                                                            to={'/'}>Home</Link>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose}><Link
                                                            to={'micuenta'}>Cuenta</Link></MenuItem>
                                                        <MenuItem onClick={() => handlePlataforma()}>
                                                            <span>Plataforma</span>
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handleCerrarSesion()}>
                                                            <span>Cerrar sesión</span>
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                            : <div style={{marginRight: '35px'}}>
                                <button className="button-default button-olive"
                                        style={{borderRadius: '4px', fontSize: '13px', marginLeft: '9px'}} type="button"
                                        onClick={() => {
                                            handdleModal(1)
                                        }}>CREAR CUENTA
                                </button>
                                <button className="button-default button-olive"
                                        style={{borderRadius: '4px', fontSize: '13px', marginLeft: '9px'}} type="button"
                                        onClick={() => {
                                            handdleModal(2)
                                        }}>LOGIN
                                </button>


                            </div>}


                    </div>
                </div>
            </div>


            {/* Start Blog Details */}
            <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color" id="casosExito">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="blog-details-inner">
                                <article className="single-post-details">
                                    <div className="post-thumbnail" data-aos="flip-left" data-aos-duration="2800">
                                        <img className="w-100"
                                             src={data[id].image}
                                             alt="Blog Images"/>
                                    </div>
                                    <div className="content mt--50">
                                        {/*<p className="first-bold">{data[id].date}</p>*/}
                                        <blockquote className="quote-content">
                                            <div className="quote-text" data-aos="zoom-in" data-aos-duration="2000">
                                                {data[id].title}
                                            </div>
                                        </blockquote>
                                        {/*<p className="first-bold">{data[id].desc}</p>*/}

                                        <p className="mt--35" data-aos="fade-right" data-aos-duration="2000" style={{
                                            whiteSpace: 'pre-wrap'
                                        }}> {data[id].parrafo}</p>
                                    </div>

                                </article>
                                <Modal isOpen={modal} toggle={() => handdleModal(0)} className={props.className}>
                                    <ModalBody>
                                        {idmodal === 2 ? <Login cerrarModalLogin={() => handdleModal(0)}/> :
                                            <RegistrarCuenta cerrarModal={() => handdleModal(0)}/>}
                                    </ModalBody>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}

            {/* Footer */}
            <Footer horizontal="horizontal"/>

        </div>)
}

export default BlogDetails
