import axios from "axios";
import React, {Component} from "react";
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Swal from "sweetalert2";

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nl_check: false,
            contact_check: false,
            inputs: {
                nl_email: '',
                contact_email: '',
                contact_asunto: '',
                contact_nombre: '',
                contact_mensaje: ''
            },
            nl_valid_email: {s: false, m: 'Ingresar email', c: "red"},
            contact_valid: {s: false, m: '', c: "red"}
        }
    }

    validar = (email) => {
            
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        // console.log(email.length, pass.length)
            if (email.length === 0) {
            this.setState({nl_valid_email: {s: false, m: 'Ingresar Email'}})
            } else {
                if (emailRegex.test(email)) {
                this.setState({nl_valid_email: {s: false, m: ''}})
                    return true
                } else {
                this.setState({nl_valid_email: {s: false, m: 'Formato email incorrecto'}})
                }
            }
            
            //this.setState({error: true, alert: true, texto: 'Rellenar todos los campos', loading:false})
            return false;
    }


    susscribrenewsletter = async () => {
        const {inputs, nl_check,} = this.state
        this.setState({nl_valid_email: {s: false, m: ""}})
        const correo = inputs.nl_email
        const answer = this.validar(correo)
        if (!nl_check) {
            this.setState({nl_valid_email: {s: true, m: "Acepte las políticas de privacidad para continuar", c: "red"}})
        } else if (answer) {
            
            const answer = await axios.post(`${process.env.REACT_APP_API_STREAMING}/landing/newsletter` , {
                email: correo
            })
            console.log(answer)
            if (answer.data.error === 0) {
                //todo bien
                Swal.fire({
                    icon: 'success',
                    text: `Email registrado exitosamente`
                })
                document.getElementById("nl_email").value = ""
                this.setState({nl_valid_email: {s: false}})
            } else {
                //correo vacio

                this.setState({nl_valid_email: {s: true, m: "Algo sucedio. Favor intentar más tarde", c: "red"}})
            }
        } else {
            this.setState({nl_valid_email: {s: true, m: "Ingrese un email válido", c: "red"}})
        }

    }

    enviarcontacto = async () => {
        let {inputs, contact_check} = this.state
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        this.setState({contact_valid: {s: false, m: "", c: "red"}})
        if (inputs.contact_email === '' || inputs.contact_mensaje === '' || inputs.contact_nombre === '' || inputs.contact_asunto === '') {
            
            this.setState({contact_valid: {s: true, m: "Rellene todos los campos para continuar", c: "red"}})
        } else if (!contact_check) {
            
            this.setState({contact_valid: {s: true, m: "Acepte las políticas de privacidad para continuar", c: "red"}})
        } else if (!emailRegex.test(inputs.contact_email)) {
            
            this.setState({contact_valid: {s: true, m: "Ingrese un email válido", c: "red"}})
        } else {
            
            const enviardata = await axios.post(`${process.env.REACT_APP_API_STREAMING}/forms/contact`, {
                asunto: inputs.contact_asunto,
                nombre: inputs.contact_nombre,
                correo: inputs.contact_email,
                mensaje: inputs.contact_mensaje,
            })
            
            if (enviardata.status === 200) {
                document.getElementById("c_correo").value = ""
                document.getElementById("c_nombre").value = ""
                document.getElementById("c_mensaje").value = ""
                console.log('Mensaje enviado')
                Swal.fire({
                    icon: 'success',
                    text: `Mensaje enviado exitosamente. Te contactaremos pronto`
                })
                // this.setState({contact_valid:{s:true,c:"white"}})
            } else {

                // this.setState({contact_valid:{s:true,m:"Algo inesperado sucedió favor intentar nuevamente",c:"red"}})
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Algo inesperado sucedió favor intentar nuevamente`
                })
            }
        }
    }

    handleInputs = (event,inp)=>{
        let {inputs} = this.state
        switch (inp) {
            case 'contact_asunto':
                inputs.contact_asunto = event.target.value;
                break;
            case 'nl_email':
                inputs.nl_email = event.target.value.toLowerCase();
                break;
            case 'contact_email':
                inputs.contact_email = event.target.value.toLowerCase();
                break;
            case 'contact_nombre':
                inputs.contact_nombre = event.target.value;
                break;
            case 'contact_mensaje':
                inputs.contact_mensaje = event.target.value.toLowerCase();
                break;
            default:
                break;
                            
        }
        //console.log(inputs)
        this.setState({inputs})
    }

    handleChange = (event, u) => {
        const {state} = this
        //console.log(state[u])
        const b = {}
        b[u] = !state[u]
        this.setState(b);
    }

    render() {
        let {check} = this.state
        return (
            <div>
                <div className={`footer-area ${this.props.horizontal}`} id="contacto">
                    <div className="footer-bg"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--40" style={{marginTop: "50px"}}>
                                    <h2 className="text-white">INNOVEMOS <span className="theme-color">JUNTOS</span>
                                    </h2>
                                    {/*<img className="image-1"*/}
                                    {/*     src={require('../assets/images/app/title-icon.png').default}*/}
                                    {/*     alt="App Landing"/>*/}
                                    {/*<img className="image-2"*/}
                                    {/*     src={require('../assets/images/app/title-icon-2.png').default}*/}
                                    {/*     alt="App Landing"/>*/}
                                    {/*<img className="image-3"*/}
                                    {/*     src={require('../assets/images/app/title-icon-3.png').default}*/}
                                    {/*     alt="App Landing"/>*/}
                                    {/* <p className="text-white">There are many variations of passages of Lorem Ipsum
                                        available, but the majorityhave suffered alteration in some form, by injected
                                        humour,available</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-xl-4 offset-xl-1">
                                <div className="contact-inner">
                                    <h3 className="title">¿CÓMO TE AYUDAMOS?</h3>
                                    {/* <form className="contact-form" > */}
                                    <div>
                                        <div className="input-box">
                                            <input type="text" id="c_asunto" placeholder="Asunto"
                                                   onChange={(e) => this.handleInputs(e, 'contact_asunto')}/>
                                        </div>

                                        <div className="input-box">
                                            <input type="text" id="c_nombre" placeholder="Nombre"
                                                   onChange={(e) => this.handleInputs(e, 'contact_nombre')}/>
                                        </div>
                                        
                                        <div className="input-box">
                                            <input type="text" id="c_correo" placeholder="Email"
                                                   onChange={(e) => this.handleInputs(e, 'contact_email')}/>
                                        </div>

                                        <div className="input-box">
                                            <textarea placeholder="Mensaje" id="c_mensaje"
                                                      onChange={(e) => this.handleInputs(e, 'contact_mensaje')}></textarea>
                                        </div>
                                        {this.state.contact_valid.s ?
                                            <Alert severity="error">{this.state.contact_valid.m}</Alert> : ''}
                                        {/*{this.state.contact_valid.s?<div style={{color:this.state.contact_valid.c}}>{this.state.contact_valid.m}</div>:<div>_</div>}*/}
                                        <div style={{color: '#ffff', marginLeft: '-9px'}}>
                                            <Checkbox
                                                checked={check}
                                                onChange={(e) => this.handleChange(e, "contact_check")}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{
                                                    color: '#ffff',
                                                    '&.Mui-checked': {
                                                        color: '#ffff',
                                                    },
                                                }}
                                            />
                                            <span style={{fontSize: '15px', fontWeight: '400'}}>Estoy de acuerdo con las
                                                <a style={{color: '#fd601c', marginLeft: '4px'}} target="_blank" rel="noreferrer"
                                                   href={'https://firebasestorage.googleapis.com/v0/b/landing-innova-connect-react.appspot.com/o/políticas%20innovaconnect%2FPolíticas%20de%20Privacidad_Página%20web%20innovaconnect.cl%2015032023.docx.pdf?alt=media&token=2d78fae7-ed82-4185-bf64-b602ecba0054'}><u><b>políticas de privacidad</b></u></a></span>
                                        </div>
                                        <div className="input-box">
                                            <button className="submite-button" type="submit"
                                                    onClick={this.enviarcontacto}>ENVIAR
                                            </button>
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-2 col-xl-4 offset-xl-2 mt_md--40 mt_sm--40">
                                <div className="contact-inner">
                                    {/* <h3 className="title">Contáctanos</h3> */}
                                    <div className="conatct-info">
                                        <div className="single-contact-info">
                                            <div className="contact-icon">
                                                <i className="zmdi zmdi-phone"></i>
                                            </div>
                                            <div className="contact-text" style={{marginTop: '4%'}}>
                                                <span>+56 2 32521525</span>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="contact-icon">
                                                <i className="zmdi zmdi-globe-alt"></i>
                                            </div>
                                            <div className="contact-text" style={{marginTop: '4%'}}>
                                                <span>CONTACTO@INNOVACONNECT.CL</span>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="contact-icon">
                                                <i className="zmdi zmdi-pin"></i>
                                            </div>
                                            <div className="contact-text" style={{marginTop: '-2%'}}>
                                                <span><br/>SANTIAGO DE CHILE</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                                <div className="newsletter text-center">
                                    <h3 className="text-white title">SUSCRÍBETE Y RECIBE NOVEDADES</h3>
                                    {/* <form  id="newsletter"> */}
                                        <div className="newsletter-content">
                                        <input type="text" name="email" id="nl_email"
                                               placeholder="Email"
                                            onChange={(e) => this.handleInputs(e, 'nl_email')}/>

                                        <div style={{marginTop: '10px'}}> {this.state.nl_valid_email.s ?
                                            <Alert severity="error">{this.state.nl_valid_email.m}</Alert> : ''}</div>
                                        {/*{this.state.nl_valid_email.s?<div style={{color:this.state.nl_valid_email.c}}>{this.state.nl_valid_email.m}</div>:<div>_</div>}*/}
                                        <button onClick={this.susscribrenewsletter} style={{height: "50px"}}
                                                className="button"><span>Enviar</span></button>
                                        <div style={{color: '#ffff', marginLeft: '-9px'}}>
                                            <Checkbox
                                                checked={check}
                                                onChange={(e) => this.handleChange(e, "nl_check")}
                                                inputProps={{'aria-label': 'controlled'}}
                                                sx={{
                                                    color: '#ffff',
                                                    '&.Mui-checked': {
                                                        color: '#ffff',
                                                    },
                                                }}
                                            />
                                            <span style={{fontSize: '15px', fontWeight: '400'}}>Estoy de acuerdo con las
                                                <a style={{color: '#fd601c', marginLeft: '4px'}} target="_blank" rel="noreferrer"
                                                   href={'https://firebasestorage.googleapis.com/v0/b/landing-innova-connect-react.appspot.com/o/políticas%20innovaconnect%2FPolíticas%20de%20Privacidad_Página%20web%20innovaconnect.cl%2015032023.docx.pdf?alt=media&token=2d78fae7-ed82-4185-bf64-b602ecba0054'}><u><b>políticas de privacidad</b></u></a></span>
                                        </div>
                                        <div className="input-box"></div>
                                        </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-xl-4 offset-xl-4">
                                <div className="footer-links text-center">
                                    <a href="https://www.facebook.com/profile.php?id=100089940467951"><i
                                        className="zmdi zmdi-facebook"></i></a>
                                    <a href="https://twitter.com/Innova360cl"><i className="zmdi zmdi-twitter"></i></a>
                                    <a href="https://www.instagram.com/innovaconnectcl"><i className="zmdi zmdi-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/92911344"><i
                                        className="zmdi zmdi-linkedin"></i></a>
                                    {/* <a href="/"><i className="zmdi zmdi-pinterest"></i></a>
                                    <a href="/"><i className="zmdi zmdi-youtube"></i></a> */}
                                </div>
                                <div className="footer-text text-center">
                                     <span style={{fontSize: '15px', fontWeight: '400'}}>
                                                <a style={{color: '#fd601c', marginLeft: '4px'}} target="_blank" rel="noreferrer"
                                                   href={'https://firebasestorage.googleapis.com/v0/b/landing-innova-connect-react.appspot.com/o/pol%C3%ADticas%20innovaconnect%2FPoliticas%20de%20Uso%20Innovaconnect%2015032023.docx.pdf?alt=media&token=484c1658-763b-42d4-8c48-3c7e49e6136f'}><u><b>Políticas de uso</b></u></a></span>
                                </div> <div className="footer-text text-center">
                                    <div style={{color: '#ffff', fontWeight: 700}}>
                                        © Powered by <a style={{color: '#fd601c'}} href='https://innova360.cl'
                                                        target="_blank" rel="noreferrer">Innova360 SpA</a>. All rights reserved 2022.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tap-top">
                    <div>
                        <i className="zmdi zmdi-long-arrow-up" ></i>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer







