import React, {Component} from "react";
import {sura, bci, comfenalco} from './data';
import {Modal, ModalBody, ModalFooter, Button} from "reactstrap";
import './style.scss';
import {Link} from 'react-router-dom';
import FormularioEmpresa from "../Formulario_empresas/formularioEmpresa";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

class Blog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            modal: false,
            toggle: false,
            informacionData: [],
            imagen: '',
            codeBlog: 0,
            idModal: 0,
        }
    }


    componentDidMount() {
        let data = [
            {
                image: sura,
                class: "fade-right",
                title: 'Mobile apps are becoming popular in 3rd world',
                link: 'casosexito/0',
                date: 'Posted on 25 Jan, 2019',
                postBy: 'Nipa Bali',
                desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour'
            },

            {
                image: bci,
                class: "fade-up",
                title: 'Latest App landing are Comming soon in latest world',
                link: 'casosexito/1',
                date: 'Posted on 25 Jan, 2019',
                postBy: 'Fatima Asrafi',
                desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour'
            },
            {
                image: comfenalco,
                class: "fade-left",
                title: 'App landing and sass landing latest post here',
                link: 'casosexito/2',
                date: 'Posted on 25 Jan, 2019',
                postBy: 'John Snow',
                desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour'
            },
        ];
        this.setState({data: data})
    }

    handleModal = (imagen, link, idModal) => {
        let {modal, codeBlog} = this.state;
        console.log(codeBlog)
        this.setState({modal: !modal, toggle: !modal, imagen: imagen, codeBlog: link, idModal: idModal})
    }


    render() {
        let {data, modal, imagen, codeBlog, idModal} = this.state

        let DataList = data.map((val, i) => {
            return (
                <div className="col-lg-4 col-md-6 col-12" id="casos-exito" key={i}
                     data-aos={val.class} data-aos-duration="2500">
                    <div className="blog">
                        <div className="thumb">
                            <img className="w-100" id="imagenStyle" src={val.image} height={240} alt="Blog thumb"
                                 onClick={() => this.handleModal(val.image, val.link, 1)}/>
                        </div>
                        <div className="inner">

                            {/*<h4 className="title"><a href={val.link}>{val.title}</a></h4>*/}
                            {/*<ul className="meta">*/}
                            {/*    <li>{val.date}</li>*/}
                            {/*    <li>By <a href="/src/pages">{val.postBy}</a></li>*/}
                            {/*</ul>*/}
                            {/*<p className="desc mt--10 mb--30">{val.desc}</p>*/}
                            {/*<div className="blog-btn">*/}
                            {/*    <a className="button-link"  href={val.link}    >Read More</a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            )
        });
        return (
            <div className="blog-area mt-lg-5 mb--160 pt--120 pt_md--80 pt_sm--80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--80" data-aos="zoom-out-up" data-aos-delay="50"
                                 data-aos-duration="1000">
                                <h2>CASOS DE<span className="theme-color"> ÉXITO</span></h2>
                                {/*<img className="image-1" src={require('../../assets/images/app/title-icon.png').default} alt="App Landing"/>*/}
                                {/*<img className="image-2" src={require('../../assets/images/app/title-icon-2.png').default} alt="App Landing"/>*/}
                                {/*<img className="image-3" src={require('../../assets/images/app/title-icon-3.png').default} alt="App Landing"/>*/}
                                <h5>Descubre cómo grandes empresas han gestionado sus procesos de Corporate Venturing
                                    con nuestra plataforma y metodología</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {DataList}
                    </div>
                    <div style={{textAlign: 'center', marginBottom: '216px'}}>
                        <h5>
                            ¡Te invitamos a ser parte de las empresas que están generando impacto organizacional con
                            nuestro acompañamiento!</h5>
                        <div className="price-btn">
                            <button className="button"
                                    onClick={() => this.handleModal('', '', 0)}
                                    type="button" style={{marginTop: '-30px', cursor: 'pointer'}}>MÁS
                                INFORMACIÓN
                            </button>
                        </div>
                        {/*<button type="button" className="button-default button-olive"*/}
                        {/*        onClick={() => {*/}
                        {/*            this.handleModal(1)*/}
                        {/*        }}>Más información*/}
                        {/*</button>*/}
                    </div>
                </div>

                <Modal isOpen={modal} toggle={this.handleModal} className="modalBlog">
                    <div style={{textAlign: 'end', margin: '13px'}}>
                        <IconButton aria-label="fingerprint" color="secondary"
                                    onClick={() => this.setState({modal: false})}>
                            <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                        </IconButton>
                    </div>
                    {idModal === 1 ?
                        <div>
                            <ModalBody>
                                <img className="w-100" src={imagen} alt="Blog thumb"/>
                            </ModalBody>
                            <ModalFooter>
                                <div className="blog-btn botonCasoExito" style={{marginRight: '16px'}}>
                                    <Link className="button-link" to={codeBlog}>MÁS INFORMACIÓN</Link>
                                </div>
                                <Button color="secondary" onClick={() => this.handleModal(-1)}>
                                    CERRAR
                                </Button>
                            </ModalFooter>
                        </div> : <ModalBody>
                            <FormularioEmpresa/>
                        </ModalBody>
                    }
                </Modal>


            </div>
        )
    }
}

export default Blog;

