
import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Modal, ModalBody} from "reactstrap";
import {useSelector} from "react-redux";

import {Redirect} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Login from "./Login/login";
import axios from "axios";
import {validarToken} from "./utils/token";

AOS.init();

const HeroOlive = React.memo((props) => {
    const [data, setdata] = React.useState({
        soluciones: 800,
        desafios_lanzados: 50,
        paises: 11
    });
    const [modal, setmodal] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const {estadoLogin} = useSelector(state => state.estadoLogin);
    const {infoUser} = useSelector(state => state.usuarios);

    const  handleModal = () => {
        setmodal(!modal)
    }


    const handlePlataforma =  async () => {
        let token = localStorage.getItem('token_2')
        let token2 = localStorage.getItem('token_3')
        let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`
        await axios.post(`${process.env.REACT_APP_API_STREAMING}/datosuser`, {
            email: localStorage.getItem('email')
        }, {headers: {authorization: token2}})
            .then((response) => {
                if (infoUser.diasvigencia > 0 || infoUser.state === 1) {
                    if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                        console.log('TOKEN VALIDO, REDIRECCIONANDO...')
                    }
                    window.open(url, '_self', 'noopener,noreferrer')
                } else {
                    setRedirect(true)
                }
            }).catch(error => {
                if( validarToken(error)) {
                    if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                        console.log('TOKEN, INVALIDO DESLOGUEANDO...')
                    }
                }
                console.log('ERROR :', error.response.data.error)
            })
    }


    useEffect(() => {
        return () => {
            let xc = navigator.userAgent;
            if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
                localStorage.setItem('dispositivo',xc )
            } else {
                localStorage.setItem('dispositivo',xc )

            }
        };
    }, []);


    return (
            <div className={`slider-area bg-color ${props.horizontal} ${props.bgshape}`} id="home"
                 style={{background: '#424242'}}>

                <div className=" h-100" id="styleContenedor">
                    <div className="row">
                        <div className="col-lg-6 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <span id="styleTitulo">CONECTAMOS SOLUCIONES TECNOLÓGICAS CON DESAFÍOS DE
                                        GRANDES CORPORACIONES  <span className="theme-color">DIGITALMENTE</span>
                                       </span>
                                    <p id="styleSubtitulo">Encuentra soluciones innovadoras de alcance
                                        regional con impacto real</p>
                                    <div className="banner-buttons vista-web">

                                            {estadoLogin ?<div className="col-lg-12" > 
                                            <button type="submit"
                                                onClick={() => handlePlataforma()} className="
                                                button-default button-olive">
                                            <i></i>
                                            <span>
                                                INGRESAR A PLATAFORMA </span>
                                            </button>{/* <button type="button" className="button-default button-olive button-border"
                                                                   onClick={()=> handlePlataforma()}>LANZA TU LLAMADO
                                            </button> <button type="button" className="button-default button-olive button-border"
                                                              onClick={()=> handlePlataforma()}>SUBE TU SOLUCIÓN
                                                </button> */} </div>:<div className="col-lg-12" >
                                                <a className="button-default button-olive button-border" href="#lanzarllamado"
                                                   role="button" >LANZA TU LLAMADO</a>
                                                <a className="button-default button-olive button-border" href="#startups"
                                                   role="button" >SUBE TU SOLUCIÓN</a>
                                                </div>

                                            }



                                        {/*<a className="button-default button-white" href="#desafios_destacados" role="button">Desafios </a>*/}
                                    </div>

                                </div>
                            </div>

                            <div className="banner-apps">
                                {/* Start Sinhle app */}
                                <div className="single-app" data-aos="zoom-in" data-aos-duration="2000">
                                    <div className="single-app-table-cell">
                                        <span style={{fontWeight: '900', fontSize: '30px', color: '#ffff'}}>
                                            +{data.soluciones}</span>
                                        <h3 style={{fontWeight: '800'}}>Soluciones</h3>
                                    </div>
                                </div>

                                {/* Start Sinhle app */}
                                <div className="single-app" data-aos="zoom-in" data-aos-duration="2000">
                                    <div className="single-app-table-cell">
                                        <span style={{fontWeight: '900', fontSize: '30px', color: '#ffff'}}>
                                            +{data.desafios_lanzados}</span>
                                        <h3 style={{fontWeight: '800', lineHeight: '19px'}}>Desafíos lanzados</h3>
                                    </div>
                                </div>

                                {/* Start Single app */}
                                <div className="single-app" data-aos="zoom-in" data-aos-duration="2000">
                                    <div className="single-app-table-cell">
                                        <span style={{fontWeight: '900', fontSize: '30px', color: '#ffff'}}>
                                            +{data.paises}</span>
                                        <h3 style={{fontWeight: '800'}}>Países</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <div className="banner-product-image " data-aos="zoom-in-up" data-aos-duration="2400" data-aos-delay="50">
                                    {/*<img src={require('../assets/images/app/pc-mockup-nuevo.gif').default}*/}
                                    {/*     alt="App Landing"/>*/}

                                    <img src={'https://firebasestorage.googleapis.com/v0/b/landing-innova-connect-react.appspot.com/o/imagenes-landing-innovaconnect%2Fpc-mockup-nuevo.gif?alt=media&token=e8745b47-a7f8-4ce2-b49f-fb5b9195011f'}
                                         alt="App Landing"/>
                                    {/*<img className="image-2"*/}
                                    {/*     src={require('../assets/images/app/gif-plataforma.gif').default}*/}
                                    {/*     alt="App Landing"/>*/}
                                    {/*<img className="image-3"*/}
                                    {/*     src={require('../assets/images/app/gif-plataforma.gif').default}*/}
                                    {/*     alt="App Landing"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal isOpen={modal} toggle={handleModal}>
                        <div style={{textAlign: 'end', margin: '13px'}}>
                            <IconButton aria-label="fingerprint" color="secondary" onClick={() =>setmodal(false)}>
                                <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                            </IconButton>
                        </div>
                        <ModalBody>
                      <Login />
                                {/*<RegistrarCuenta abrirModal={() => setmodal(true)}*/}
                                {/*                 cerrarModal={() => setmodal(false)}/>*/}
                        </ModalBody>
                    </Modal>
                    { redirect ? (<Redirect push to="/micuenta"/>) : null }
                </div>
            </div>

    );
});

export default HeroOlive;







