import React from 'react';
import {Row, Col, Modal, ModalBody} from 'reactstrap'
import imagen1 from "../../assets/images/lanza-tu-llamado/imagen-para-el-banner-lanza-tu-llamado.jpg";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import './style.css'
import Login from "../Login/login";

const LanzarLlamado = () => {
    const {estadoLogin} = useSelector(state => state.estadoLogin);
    const [modal, setmodal] = React.useState(false);
    const [idmodal, setIdModal] = React.useState(0);
    const [redirect, setRedirect] = React.useState(false);
    const {infoUser} = useSelector(state => state.usuarios);

    const handlePlataforma = () => {
        let token = localStorage.getItem('token_2')
        let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`
        if (infoUser.diasvigencia > 0 || infoUser.state === 1) {
            window.open(url, '_self', 'noopener,noreferrer')
        } else {
            setRedirect(true)
        }
    }

    function handleModal(id = 0) {
        // console.log('ENTRE A ABRIR MODAL DESDE DESAFIOS')
        setmodal(true)
        setIdModal(id)
    }

    function handleModal(id = 0) {
        // console.log('ENTRE A ABRIR MODAL DESDE DESAFIOS')
        setmodal(true)
        setIdModal(id)
    }

    function handleCerrarModal(id = 0) {
        // console.log('ENTRE A ABRIR MODAL DESDE DESAFIOS')
        setmodal(false)

    }

    return (
        <div className="h-100 pt--50 pt_sm--180 " id="lanzarllamado" data-aos-duration="2500">
            <div className="col-lg-12">
                <div className="section-title text-center mb--40" data-aos="zoom-out-up" data-aos-delay="50"
                     data-aos-duration="1000">
                    <h2 style={{marginBottom:'40px'}}>LANZA TU <span className="theme-color">LLAMADO</span></h2>
                    <div className="colorfondoLanzarllamado">
                        <Row>
                            <Col md={5} style={{alignSelf:'center'}}>
                                <div className=" contendorLanzaTuLlamado">
                                    <div style={{marginLeft: '13px', marginTop: '50px'}}>
                                        <div className="tituloLanzaLlamado">
                                            <span style={{ fontSize: '29px'}}>LANZA UN LLAMADO A TODO EL
                                                ECOSISTEMA DE LATAM Y</span>
                                            <h4 id="subtitulo">
                                                DESCUBRE TECNOLOGÍAS PARA TU ÁREA DE NEGOCIO
                                            </h4>
                                        </div>

                                        <div style={{ lineHeight: '20px', marginTop: '50px'}}>
                                            <span style={{fontSize: '25px', fontWeight: 800}}>
                                               ¡Hoy todas las áreas pueden incorporar tecnologías en sus operaciones!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="mobileimg" style={{textAlign: 'center'}}>
                                    <img src={imagen1} width="100%" height={436} alt="BannerCocaCola"
                                         style={{borderRadius: '13px'}}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className={'planes'}>
                <div className={`planes-area`} id='planes'>
                </div>
                    <div className="btnLlamado col-5">
                        {estadoLogin ?
                            <div className="styleBtnPostular">
                                <p style={{padding: '2px', cursor: 'pointer'}}
                                    onClick={() => handlePlataforma()}>LANZA TU LLAMADO AQUÍ</p>
                            </div>
                            :
                            <div className="styleBtnPostular">
                                <p style={{padding: '2px', cursor: 'pointer'}}
                                    onClick={() => handleModal()}>LANZA TU LLAMADO AQUÍ</p>
                            </div>
                        }

                    </div>
                {/* <div className="row" style={{alignSelf: 'center', marginTop: '0px'}}> */}
                    {/*<div className="col-7" style={{lignSelf: 'center'}}>*/}
                    {/*    <div style={{marginLeft: '5px', fontSize: '14px'}}>*/}
                    {/*        <p style={{fontWeight: 800}}>¡No te pierdas esta oportunidad!</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                {/* </div> */}
            </div>
            {/* <div style={{marginTop: '13px'}}><span style={{color: 'grey', }}>*Para poder acceder a este beneficio debes tener una cuenta activa</span>
            </div> */}
            {redirect ? (<Redirect push to="/micuenta"/>) : null}
            <Modal isOpen={modal} toggle={handleCerrarModal}>
                <div style={{textAlign: 'end', marginRight: '10px', marginTop: '10px'}}>
                    <IconButton aria-label="fingerprint" color="secondary" onClick={() => setmodal(false)}>
                        <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                    </IconButton>
                </div>
                <ModalBody>
                    <Login cerrarModalLogin={() => handleCerrarModal(1) } variable={'lanzarLlamado'}/>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default LanzarLlamado;
