import axios from "axios";
import React, {useState} from 'react';
import logoInnova from "../../assets/images/logo/Logo InnovaConnect.png";
import {Col, Row, Button, Modal, ModalBody, Form} from "reactstrap";
import IconButton from "@mui/material/IconButton";
import StartupForm from "../Update_sub/StartupForm";
import CloseIcon from "@mui/icons-material/Close";
import {Link,Redirect} from "react-router-dom";
import {validarToken} from "../utils/token";
import {useSelector} from "react-redux";

const UpdateSub = React.memo((props) => {
    const [modal, setmodal] = useState(props.modalito);
    const [ocultarBtn, setOcultarBtn] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const {infoUser} = useSelector(state => state.usuarios);
    const [idmodal, setidmodal] = React.useState(false);

    function handdleModal (id) {
        setmodal(true)
        setIdModal(id)
        // console.log('soy', modal)
    }
    const handdleCerrarModal=()=>{
        setmodal(false)
        setIdModal(4)
    }

    const handlePlataforma =  async () => {
        let token = localStorage.getItem('token_2')
        let token2 = localStorage.getItem('token_3')
        let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`
        await axios.post(`${process.env.REACT_APP_API_STREAMING}/datosuser`, {
            email: localStorage.getItem('email')
        }, {headers: {authorization: token2}})
            .then((response) => {
                if (infoUser.diasvigencia > 0 || infoUser.state === 1) {
                    if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                        console.log('TOKEN VALIDO, REDIRECCIONANDO...')
                    }
                    window.open(url, '_self', 'noopener,noreferrer')
                } else {
                    setRedirect(true)
                }
            }).catch(error => {
                if( validarToken(error)) {
                    if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                        console.log('TOKEN, INVALIDO DESLOGUEANDO...')
                    }
                }
                console.log('ERROR :', error.response.data.error)
            })
    }

    const handleStartup = () => {
        props.cerrarModal()
        props.abrirModal()
    }

    return (
        <div>
            {localStorage.getItem('dispositivo') === 'mobile' ? '' :
                <div style={{textAlign: 'center'}}>
                    <h3 className="theme-color ">ACTUALIZA TU SUSCRIPCIÓN</h3>
                </div>
            }
            <div style={{width: '100%', height: '30%', textAlign: 'center'}}>
                <img src={logoInnova} width={170} height={115} alt="logo innova"/>
            </div>
            <br/>

            {props.diasvigencia === 0 ? 
            <div>
                <div style={{textAlign: 'center', fontWeight: '600', marginTop: '15px', marginBottom: '60px'}}>
                    <p style={{fontSize: '20px'}}>¡Tu prueba gratis ha finalizado!</p>
                    <p style={{fontSize: '20px'}}>Actualiza tu suscripción para no quedar sin conocer las tendencias en innovación.</p>
                    <p style={{fontSize: '20px'}}>¡Recuerda, si eres startup sólo debes subir tu solución a la plataforma para tener gratuidad!</p>
                </div>
            </div> : 
            <div>
                <div style={{textAlign: 'center', fontWeight: '600', marginTop: '15px', marginBottom: '60px'}}>
                    <p style={{fontSize: '20px'}}>Quedan <span className="theme-color ">{props.diasvigencia}</span> dias para finalizar tu prueba gratis, actualiza tu suscripción para no 
                        quedarte sin conocer las tendencia en innovación. 
                        Si eres startup recuerda que puedes solicitar tu acceso gratis.</p>
                </div>
            </div> 
            }
            <div className="contenedorBoton ">
                <Row>
                    <Col md={6} style={{justifyContent:'start'}}>
                        {props.diasvigencia === 0  ? 
                            <Button id="styleButton" size="md"  disabled={ocultarBtn} onClick={() =>handlePlataforma()}>Ver planes</Button> : 
                            <Link to="/micuenta"><Button id="styleButton" size="md"  disabled={ocultarBtn}>Ver planes</Button></Link>
                        }
                    </Col>
                    <Col md={6} style={{justifyContent:'end'}}>
                        <Button id="styleButton" size="md"  disabled={ocultarBtn} onClick={() => handleStartup()}>Soy Startup</Button>
                    </Col>
                </Row>
                {props.diasvigencia === 0 ?
                <div></div> : 
                <div className="contenedorBoton mt-10 ">
                    <Button  style={{
                        background: 'transparent',
                        color: '#fd601c'
                    }} id="pointer" onClick={() => handlePlataforma()}>
                        Entrar a la plataforma
                    </Button>
                </div>
                }
                    
            </div>
            {redirect ? <Redirect push to="/micuenta" />: null}
            {/*{redirectHome ? <Redirect push to="/" />: null}*/}

            <Modal isOpen={modal} toggle={() => handdleCerrarModal()}>
                <div style={{textAlign: 'end', margin: '13px'}}>
                    <IconButton aria-label="fingerprint" color="secondary" onClick={() => handdleCerrarModal()}>
                        <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                    </IconButton>
                </div>
                <ModalBody>
                </ModalBody>

            </Modal>
        </div>
    );
})

export default UpdateSub