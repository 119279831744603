import React ,  { Component } from "react";

class About extends Component{
    render(){
        return(
            <div className={`app-about ${this.props.horizontalabout}`}  id="about">
                <div className="">
                    <div className="row">
                        <div className="col-lg-12" data-aos="zoom-out-up" data-aos-delay="50"
                             data-aos-duration="1000">
                            <div className="section-title text-center mb--40" style={{marginLeft:'10px', marginRight:'10px',
                                marginTop: '12px'}}>
                                <h2>LA PRIMERA VITRINA DE SOLUCIONES TECNOLÓGICAS<br/> <span className="theme-color">E INNOVACIÓN DE LATAM</span> </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 offset-lg-1 mt--40">
                            <div className="about-thumbnail mr--5" data-aos="fade-right" data-aos-duration="2400">
                                <img className="image-1" src={require('../../assets/images/app/imagen01connect.png').default} alt="App Landing"/>
                                {/*<img className="image-2" src={require('../../assets/images/app/mobile-2.png').default} alt="App Landing"/>*/}
                                {/*<img className="image-3" src={require('../../assets/images/app/mobile-3.png').default} alt="App Landing"/>*/}
                            </div>
                        </div>
                        <div className="col-lg-5 mt--40" data-aos="fade-up-left" data-aos-duration="2000" data-aos-delay="50"
                           >
                            <div className="about-content m-lg-5 m-sm-2" style={{    fontSize: '21px',
                                fontWeight: 300,marginLeft:'10px', marginRight:'10px' }}>
                                <p>Marketplace que facilita el descubrimiento de tecnologías para acelerar la transformación digital de tu empresa.</p>
                                <p>Vincúlate con el ecosistema de innovación, descubriendo, conectando y colaborando, en un solo lugar.</p>
                                <p>Descubre  soluciones y desafíos en  nuestra plataforma.
                                </p>
                                <div className="about-buttons">
                                    <a className="button-default button-olive button-border" href="#solucionesdestacadas" role="button" style={{marginRight:'10px'}}>soluciones</a>
                                    <a className="button-default button-border" href="#desafios" role="button">desafíos</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











