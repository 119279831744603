import React, {useEffect} from "react";
import {Row, Col, Modal, ModalBody} from 'reactstrap'
import './style.css'
import logo from '../../assets/images/desafio_destacado/sigma.png'
import imagen1 from '../../assets/images/desafio_destacado/imagen-desafios2.jpg'
import imagen2 from '../../assets/images/desafio_destacado/empresa_1.png'
import {useDispatch, useSelector} from "react-redux";
import Login from "../Login/login";
import Dialogo from '../../components/Dialogo/dialogo'
import {Redirect} from "react-router-dom";
import axios from "axios";
import {fetchInfoUser} from "../../contexts/Redux/reducers/usuario";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";



const DesafioDestacado = (props) => {
    const [data, setData] = React.useState([]);
    const [modal, setmodal] = React.useState(false);
    const [idmodal, setIdModal] = React.useState(0);
    const [redirect, setRedirect] = React.useState(false);
    const {infoUser} = useSelector(state => state.usuarios);
    const {estadoLogin} = useSelector(state => state.estadoLogin);
    const dispatch = useDispatch()

    useEffect(() => {
        let data = {
            imagenWeb: imagen1,
            imagenMovil: imagen2,
            logo: logo,
            titulo1: 'Buscamos a las startups más innovadoras de LATAM!',
            subtitulo: '',
            texto1: `Tu solución puede ser una de las 25 finalistas de los 5 desafios de innovación abierta el Corporate Venture Summit de LATAM este 4 y 5 de septiembre `,
            textto2: `Tendrás la oportunidad de presentar frente a una gran audiencia: Corporativos, VCs, CVCs, Inversioistas y representantes del ecosistema de emprendimiento e innovacíon latinoamericano.`
        }
        setData(data)
    }, []);

    function handleModal (id =0){
        // console.log('ENTRE A ABRIR MODAL DESDE DESAFIOS')
        setmodal(true)
        setIdModal(id)
    }
    function handleCerrarModal (id =0){
        // console.log('ENTRE A ABRIR MODAL DESDE DESAFIOS')
        setmodal(false)

    }
    function infoUsuarioHanndle() {
        const token2 =  localStorage.getItem('token_3')
        if (localStorage.getItem('token_2')) {
            axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/datosuser`, {
                email: localStorage.getItem('email')
            },{headers:{authorization: token2}}).then((response) => {
                    // console.log('ACTUALIZADO INFORMACION DE USUARIO response')
                    let informacion = response.data

                    const noValido = / /
                    if (noValido.test(informacion.name)) {
                        let nombre = informacion.name.split(" ",)
                        let nombrep = nombre[0].charAt(0).toUpperCase();
                        let apellido = nombre[1].charAt(0).toUpperCase();
                        let n = nombre[0].charAt(0).toUpperCase() + nombre[0].slice(1);
                        let a = nombre[1].charAt(0).toUpperCase() + nombre[1].slice(1);
                        informacion.nombreAbreviado = `${nombrep}${apellido}`
                        informacion.nombre = `${n}`
                        informacion.apellido = `${a}`

                    }else {
                        let nombre = informacion.name
                        let nombrep = nombre[0].charAt(0).toUpperCase();
                        informacion.nombreAbreviado = nombrep
                        informacion.nombre = nombre
                        informacion.apellido = 's'

                    }
                    dispatch(fetchInfoUser(informacion))

                }
            )
            .catch((error) => {
                console.log('ERROR :', error.response.data.error)
            })
        }
    }

    const handlePlataforma = () => {
        let token = localStorage.getItem('token_2')
        let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`
        if (infoUser.diasvigencia > 0 || infoUser.state === 1 ) {
            window.open(url, '_self', 'noopener,noreferrer')
        } else {
            setRedirect(true)
        }
    }
    return (
        <div className="h-100 pt--90 pt_sm--100 mb--190 " id="desafiodestacado">
            <div className="col-lg-12">
                <div className="section-title text-center mb--40" data-aos="zoom-out-up" data-aos-delay="50"
                     data-aos-duration="1000">
                    <h2>DESAFÍO <span className="theme-color">DESTACADO</span></h2>

                </div>
            </div>
            <div className="colorDesafio">
                <Row>
                    <Col md={5}>
                        <div className=" contendorDesafioDestacado">
                            <div style={{marginLeft: '13px'}}>
                                {/* <div className="contenedorLogoEmpresa">
                                    <img src={logo} alt="logoCocacola" width={600} height={60}/>
                                </div> */}
                                <div className="titulo1">
                                    <h3>{data.titulo1}</h3>
                                </div>
                                <div style={{fontWeight: 500}}>
                                    <h5 id="titulo2">
                                        {data.subtitulo}
                                    </h5>
                                </div>
                                <div style={{marginBottom: '24px'}}>
                                    <p style={{lineHeight: '19px', fontSize: '20px'}}>
                                        {data.texto1}
                                    </p>

                                    <p style={{lineHeight: '19px', fontSize: '20px'}}>
                                        {data.textto2}
                                    </p>
                                </div>
                                <div className="row" style={{lignSelf: 'center', marginTop: '20px'}}>
                                    {/* <div className="col-7" style={{lignSelf: 'center'}}>
                                        <div style={{marginLeft: '5px', fontSize: '14px'}}>
                                            <p style={{fontWeight: 800}}>¡No te pierdas esta oportunidad!</p>
                                        </div>
                                    </div> */}
                                    <div className="col-2" style={{lignSelf: 'center', alignContent: 'center'}}>
                                        {estadoLogin ?
                                            <div className="styleBtnPostular">
                                                <p style={{fontSize: '20px', padding: '2px', cursor: 'pointer'}}
                                                   onClick={() => handlePlataforma()}>Postula aquí</p>
                                            </div>
                                            :
                                            <div className="styleBtnPostular">
                                                <p style={{fontSize: '20px', padding: '2px', cursor: 'pointer'}}
                                                   onClick={() => handleModal()}>Postula aquí</p>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className="mobileimg" style={{textAlign: 'center'}}>
                            <img src={imagen1} width="100%"  alt="BannerCocaCola"
                                 style={{borderRadius: '13px'}}/>
                        </div>
                        <div>
                            <div style={{color: '#ffff', display: 'flex', marginTop: '7px'}}>
                                <h4 style={{fontWeight: 900, marginRight: '5px', fontFamily: 'Lato'}}>Postulación
                                    hasta:</h4>
                                <h4 style={{fontWeight: 300, fontFamily: 'Lato'}}> 31 julio</h4></div>
                            {/*<img src={imagen2} width="100%" height="130" alt="fechasbci"/>*/}
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={modal} toggle={handleCerrarModal}>
                <div style={{textAlign: 'end',     marginRight: '10px', marginTop: '10px'}}>
                <IconButton aria-label="fingerprint" color="secondary" onClick={() =>setmodal(false)}>
                        <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                    </IconButton>
                </div>
                <ModalBody>
                    { idmodal === 3  ? <Dialogo  diasvigencia={infoUser.diasvigencia}
                                                 fechaVigencia={moment(infoUser.fecha_vigencia).format('DD-MM-YYYY')}/> :
                        <Login cerrarModalLogin={() => handleCerrarModal(1)}
                               infoUsuario={() => infoUsuarioHanndle()}
                               abrirDialogo={() => handleModal(3)} /> }
                </ModalBody>
            </Modal>

            {redirect ? (<Redirect push to="/micuenta"/>) : null}

        </div>

    );
};

export default DesafioDestacado;