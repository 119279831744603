import React, { useEffect } from 'react';
import './style.css'

import logoTitle from "../../assets/images/logo/Logo-InnovaConnect-blanco.png"
import logo from "../../assets/images/logo/Logo InnovaConnect.png"

import { Widget, addResponseMessage } from 'react-chat-widget';

import axios from "axios";

import 'react-chat-widget/lib/styles.css';

const soporte = () => {
    const soporteArray = [];
    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    useEffect(() => {
        addResponseMessage('Escriba su email');
      }, []);
    
      const handleNewUserMessage = (newMessage) => {
        if (soporteArray.length > 1) {
            soporteArray.push(newMessage);
            addResponseMessage('Gracias! pronto el área de soporte se contactará contigo');
            envioSoporte();
        } if (soporteArray.length === 1) {
            soporteArray.push(newMessage);
            addResponseMessage('Describe tu problema o solicitud');
        } if (soporteArray.length === 0) {
            if (emailRegex.test(newMessage)) {
                soporteArray.push(newMessage);
                addResponseMessage('Dinos tu nombre y apellido');
            } else {
                addResponseMessage('Formato email incorrecto');
            }
        }
      };

      const envioSoporte = async () => {
        const data = {
            correo: soporteArray[0],
            mensaje: soporteArray[2],
            asunto: 'Soporte InnovaConnect',
            nombre: soporteArray[1]
        };
        await axios.post(`${process.env.REACT_APP_API_STREAMING}/forms/support`, data);
      };


    return (
        <div className="soporte">
            <Widget
                titleAvatar={logoTitle}
                profileAvatar={logo}
                handleNewUserMessage={handleNewUserMessage}
                title="Soporte"
                subtitle=""
                senderPlaceHolder="Escribe tu mensaje..."
                />
        </div>

    )
        ;
}


export default soporte;
