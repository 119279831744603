import React from "react";
import Slider from "react-slick";
import {comentarios} from "./data";
import {testimonial, testimonial2} from "./script";

class Testimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.testimonial,
            nav2: this.testimonial2
        });
    }


    render() {
        return (
            <div className="testimonial-wrapper pt--180 text-center" id="testimonio" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" data-aos="fade-down"
                             data-aos-easing="linear"
                             data-aos-duration="1000">
                            <div className="testimonial-activation">
                                <div className="section-title text-center mb--80">
                                    {/*<h2>APP <span className="theme-color">REVIEWS</span></h2>*/}
                                    {/*<img className="image-1"*/}
                                    {/*     src={require('../../assets/images/app/title-icon.png').default}*/}
                                    {/*     alt="App Landing"/>*/}
                                    {/*<img className="image-2"*/}
                                    {/*     src={require('../../assets/images/app/title-icon-2.png').default}*/}
                                    {/*     alt="App Landing"/>*/}
                                    {/*<img className="image-3"*/}
                                    {/*     src={require('../../assets/images/app/title-icon-3.png').default}*/}
                                    {/*     alt="App Landing"/>*/}
                                    {/*<p>There are many variations of passages of Lorem Ipsum available, but the*/}
                                    {/*    majorityhave suffered alteration in some form, by injected humour,available</p>*/}
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3" >
                                        <Slider {...testimonial} asNavFor={this.state.nav2}
                                                ref={slider => (this.testimonial = slider)}
                                                className="testimonial-image-slider text-center">
                                            {comentarios.map((res, index) => (

                                                    <div className="sin-testiImage" key={index}>
                                                        <img src={res.img} width={"100%"} alt="testimonial 1"/>
                                                    </div>
                                            ))}
                                        </Slider>

                                    </div>
                                </div>

                                <Slider {...testimonial2} asNavFor={this.state.nav1}
                                        ref={slider => (this.testimonial2 = slider)}
                                        className="testimonial-text-slider text-center">
                                    {comentarios.map((res, index) => (

                                        <div className="sin-testiText" key={index}>
                                            <h2 style={{marginTop:'3px'}}>{res.autor}</h2>
                                            <h5>{res.cargo}</h5>
                                            <div className="client-rating">
                                                {/*<i className="zmdi zmdi-star color"></i>*/}
                                                {/*<i className="zmdi zmdi-star color"></i>*/}
                                                {/*<i className="zmdi zmdi-star color"></i>*/}
                                                {/*<i className="zmdi zmdi-star color"></i>*/}
                                                {/*<i className="zmdi zmdi-star-half color"></i>*/}
                                            </div>
                                            <p>{res.frase}</p>
                                        </div>
                                    ))}

                                </Slider>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Testimonial;







