import axios from "axios";
import React, {useState} from 'react';
import logoInnova from "../../assets/images/logo/Logo InnovaConnect.png";
import {Col, Row, Button, Modal, ModalBody, Form} from "reactstrap";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import OlvideContrasenia from "./olvideContrasenia";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";
import {fetchEstadoTrue, fetchEstadoFalse} from "../../contexts/Redux/reducers/login";
import {fetchInfoUser} from "../../contexts/Redux/reducers/usuario";
import {useDispatch} from 'react-redux'
import Swal from "sweetalert2";
import RegistrarCuenta from "../Registrar_cuenta/registrarCuenta";
import CloseIcon from "@mui/icons-material/Close";
import {Redirect} from "react-router-dom";
import {validarToken} from "../utils/token";

const Login = React.memo((props) => {
    const [modal, setmodal] = useState(props.modalito);
    const [alert2, setAlert2] = useState(false);
    const [error, seterror] = useState(false);
    const [ocultarBtn, setOcultarBtn] = useState(false);
    const [alert, setalert] = useState(false);
    const [loading, setloading] = useState(false);
    const [texto, settexto] = useState(false);
    const [idModal, setIdModal] = useState(0);
    const [dataInfo, setDataInfo] = useState(false);
    // const [redirectHome, setRedirectHome] = useState(false);
    const [redirect, setRedirect] = useState(false)
    const [validarEmail, setvalidarEmail] = useState(false);
    const [validarPassword, setvalidarPassword] = useState(false);
    const [formatoCorreo, setformatoCorreo] = useState('');
    const [eye, seteye] = useState('password');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const dispatch = useDispatch()


    const handdlePassword = () => {
        if (eye === 'password') {
            seteye('text')
        } else {
            seteye('password')
        }
    }
    const handleInputs = (event, inp) => {
        switch (inp) {
            case 'email':
                setemail(event.target.value)
                break;
            case 'password':
                setpassword(event.target.value)
                break;
            default:
                break;
        }
    }
    function handdleModal (id) {
        setmodal(true)
        setIdModal(id)
        // console.log('soy', modal)
    }
    const handdleCerrarModal=()=>{
        setmodal(false)
        setIdModal(0)

    }
    const validar = (email, pass) => {
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        let final = false
        setloading(true)

        // console.log(email.length, pass.length)
        if (email.length === 0 || pass.length === 0) {
            if (pass.length === 0) {
                setvalidarPassword(true)
            } else {
                setvalidarPassword(false)
            }
            if (email.length === 0) {
                setvalidarEmail(true)
                setloading(false)

            } else {
                setloading(false)
                let formato = emailRegex.test(email) ? '' : 'Email incorrecto'

                if (formato) {
                    // console.log("EMAIL INCORRECTO 4")
                    setvalidarEmail(true)
                    setformatoCorreo(formato)
                    setloading(false)
                } else {
                    setvalidarEmail(false)
                    // console.log("EMAIL INCORRECTO 1")
                    setloading(false)
                    setformatoCorreo(formato)
                }
            }
            seterror(true)
            setalert(true)
            settexto('Rellenar todos los campos')

            return false;
        }
        else {
            let formato = emailRegex.test(email) ? '' : 'Email incorrecto'
            if (pass.length >= 1) {
                setvalidarPassword(false)
            }
            if (formato) {
                // console.log("EMAIL INCORRECTO 3")
                setvalidarEmail(true)
                setformatoCorreo(formato)
                setloading(false)
            } else {
                // console.log("EMAIL INCORRECTO 2")
                seterror(false)
                setalert(false)
                setvalidarPassword(false)
                setvalidarEmail(false)
                setloading(false)
                setformatoCorreo(formato)
                final = true
            }

            return final
        }

    }
    const verificarDiaVigencia =(status, dia)=>{
        // diaVigeniaAlerta <= 5 && status === 1
        // INFORMA QUE LE QUEDAN POCOS DIAS PARA TERMINAR SU TRIAL
        if(dia <= 15 && status === 2) {
            props.abrirDialogo()
        }
        if(dia === 0 && status === 3) {
            props.abrirDialogo()
        }
    }

    const submit = async () => {
        let status = ''
        let dia = ''
        const valida = validar(email, password)
        let navBar2 = false
       let navBar =  props.navBar2 ?  props.navBar2 :navBar2;
        // setloading(true)

        if (valida) {
            setloading(true)
            // console.log(email)
            // console.log(`${process.env.REACT_APP_API_INNOVACONNECT}/landing/verifyUser`)
            await axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/landing/verifyUser`, {
                email: email,
                password: password
            })
                .then(async (data) => {
                    // console.log(data.data)
                    let res = data.data.error;
                    if (res === 0) {
                        // LOGIN Qaconnect/loginUE SEVUELVE TOKEN
                        await axios.post(`${process.env.REACT_APP_API_INNOVACONNECT}/landing/loginToken`, {
                            password: password,
                            email: email.toLowerCase()
                        })
                            .then(async (data) => {
                                if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                                    console.log('CREANDO TOKEN..')
                                    console.log(data.data)
                                }
                                let res = data.data.error
                                let token = data.data.token
                                let token2 = data.data.token_2
                                if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                                    console.log("INGRESANDO A LOGIN TOKEN")
                                    console.log('TOKEN CREADO', token)
                                }
                                if (res === 0 || res === '0') {
                                    setOcultarBtn(true)
                                    setloading(false)
                                    localStorage.setItem('token_2', token)
                                    localStorage.setItem('token_3', token2)
                                    localStorage.setItem('email', email)
                                    dispatch(fetchEstadoTrue());
                                    setmodal(false)
                                    setAlert2(true)
                                    settexto('Inicio de sesión exitoso')
                                }
                                if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                                    console.log("INGRESANDO A DATO USER")

                                }
                                await axios.post(`${process.env.REACT_APP_API_STREAMING}/datosuser`, {
                                    email: localStorage.getItem('email')
                                },{headers:{authorization: token2}})
                                    .then((response) => {
                                        if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                                            console.log("INGRESANDE A DATO USER")
                                        }
                                    let informacion = response.data
                                    // console.log(informacion["diasvigencia"])
                                    //     console.log(informacion)
                                    status = informacion["state"]
                                    dia = informacion["diasvigencia"]

                                    const noValido = / /
                                    if (noValido.test(informacion.name)) {
                                        let nombre = informacion.name.split(" ",)
                                        let nombrep = nombre[0].charAt(0).toUpperCase();
                                        let apellido = nombre[1].charAt(0).toUpperCase();
                                        let n = nombre[0].charAt(0).toUpperCase() + nombre[0].slice(1);
                                        let a = nombre[1].charAt(0).toUpperCase() + nombre[1].slice(1);
                                        informacion.nombreAbreviado = `${nombrep}${apellido}`
                                        informacion.nombre = `${n}`
                                        informacion.apellido = `${a}`
                                    } else {
                                        let nombre = informacion.name
                                        let n = nombre[0].charAt(0).toUpperCase() + nombre[0].slice(1);
                                        informacion.nombreAbreviado = n
                                        informacion.nombre = nombre
                                        informacion.apellido = ''
                                    }
                                        if(props.variable === 'lanzarLlamado'){
                                            let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`
                                            if (informacion.diasvigencia > 0 ) {
                                                window.open(url, '_self', 'noopener,noreferrer')
                                            } else {
                                               setRedirect(true)
                                                return;
                                            }
                                        }
                                    dispatch(fetchInfoUser(informacion))
                                    setDataInfo(informacion)
                                    if(navBar){
                                        props.infoUsuario()
                                    }

                                })
                                .catch((error) => {
                                    console.log('ERROR :', error.response.data.error)
                                })
                                    .then(async () => {
                                        await axios.post(`${process.env.REACT_APP_API_STREAMING}/primeringreso`, {
                                            },{headers:{authorization: token2}})
                                            .then((response) => {
                                                if(`${process.env.REACT_APP_ENTORNO}` ==='develop'){
                                                    console.log("INFORMACION PRIMER INGRESO")
                                                    console.log(response)
                                                }
                                            })
                                            .catch((error) => {
                                                setloading(false)
                                                console.log('[ERROR]: login /primeringreso', error)
                                            })
                                    })
                                    .catch((error) => {
                                        setloading(false)
                                        Swal.fire({
                                            icon: 'error',
                                            // title: 'Oops...',
                                            text: 'Ha ocurrido un error intente nuevamente',
                                        })
                                        console.log('[ERROR]: login datouser', error)
                                        return;
                                    })
                            })
                            .then( async () => {
                                 props.cerrarModalLogin()
                            }).then(() => verificarDiaVigencia(status,dia))
                            .catch(error => {
                                // if( validarToken(error)) setRedirectHome(true)
                                console.log(error)
                            })
                    }
                    if (res === 400) {
                        setalert(true)
                        settexto(data.data.mensaje)
                        setloading(false)
                        dispatch(fetchEstadoFalse());
                    }
                    if (res === 401) {
                        setalert(true)
                        settexto(data.data.mensaje)
                        setloading(false)
                        dispatch(fetchEstadoFalse());
                    }
                    if (res === 402) {
                        setalert(true)
                        settexto(data.data.mensaje)
                        setloading(false)
                        dispatch(fetchEstadoFalse());
                    }
                    if (res === 403) {
                        setalert(true)
                        settexto(data.data.mensaje)
                        setloading(false)
                        dispatch(fetchEstadoFalse());
                    }
                })
                .catch((error) => {
                    setloading(false)
                    Swal.fire({
                        icon: 'error',
                        // title: 'Oops...',
                        text: 'Ha ocurrido un error intente nuevamente',
                    })
                    console.log('[ERROR]: login verifyUser', error)
                    return;
                })
        }
    }

    return (
        <div>
            {localStorage.getItem('dispositivo') === 'mobile' ? '' :
                <div style={{textAlign: 'center'}}>
                    <h3 className="theme-color ">INICIO DE SESIÓN</h3>
                </div>
            }
            <div style={{width: '100%', height: '30%', textAlign: 'center'}}>
                <img src={logoInnova} width={170} height={115} alt="logo innova"/>
            </div>
            <br/>

            {localStorage.getItem('dispositivo') === 'mobile' ? <div>
                <div style={{textAlign: 'center', fontWeight: '600', marginTop: '15px', marginBottom: '60px'}}>
                    <p>Plataforma de innovación que busca vincular soluciones tecnológicas para resolver los desafíos
                        reales de las corporaciones.</p>
                </div>

                <div style={{textAlign: 'center', marginBottom: '25px'}}>
                    <h4 className="theme-color" style={{fontWeight: 800}}> !DESCARGA NUESTRA APLICACIÓN!</h4>
                </div>
                <Col md={12}>
                    <Row style={{justifyContent: 'center'}}>
                        <Col md={5} xs={6} style={{cursor: 'pointer', marginBottom: '30px'}}>
                            <div style={{
                                background: 'black',
                                color: '#ffff',
                                padding: '6px',
                                borderRadius: '7px',
                                textAlign: 'center'
                            }}
                                 onClick={() => window.open('https://apps.apple.com/cl/app/innovaconnect/id1580625212')}>
                                <div style={{display: 'flex'}}>
                                    <Col md={3} style={{textAlign: 'center'}}>
                                        <i style={{fontSize: '46px'}} className="zmdi zmdi-apple"></i>
                                    </Col>
                                    <Col md={9} style={{alignSelf: 'center'}}>
                                        <div style={{marginLeft: '11px', textAlign: 'start'}}>
                                            <p style={{
                                                color: 'grey',
                                                fontWeight: '400',
                                                fontSize: '10px',
                                                lineHeight: '18px',
                                                marginBottom: '0px',

                                            }}>
                                                DISPONIBLE EN
                                            </p>
                                            <p style={{
                                                fontWeight: '700',
                                                fontSize: '20px',
                                                color: '#ffff'
                                            }}>App Store</p>
                                        </div>
                                    </Col>
                                </div>

                            </div>
                        </Col>
                        <Col md={5} xs={6} style={{cursor: 'pointer'}}>
                            <div style={{
                                background: 'black',
                                color: '#ffff',
                                padding: '6px',
                                borderRadius: '7px',
                                textAlign: 'center'
                            }}
                                 onClick={() => window.open('https://play.google.com/store/apps/details?id=com.innova360.connect')}>
                                <div style={{display: 'flex'}}>
                                    <Col md={3} style={{textAlign: 'center'}}>
                                        <i style={{fontSize: '46px'}} className="zmdi zmdi-google-play"></i>
                                    </Col>
                                    <Col md={9} style={{alignSelf: 'center'}}>
                                        <div style={{marginLeft: '11px', textAlign: 'start'}}>
                                            <p style={{
                                                color: 'grey',
                                                fontWeight: '400',
                                                fontSize: '10px',
                                                marginBottom: '0px',
                                                lineHeight: '18px'
                                            }}>
                                                DISPONIBLE EN
                                            </p>
                                            <p style={{
                                                fontWeight: '700',
                                                fontSize: '20px',
                                                color: '#ffff'
                                            }}>Play Store</p>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div> :
                <Form>
                <Row>
                    <Col md="12">
                        <div style={{marginLeft: '20px', marginRight: '20px'}}>
                            <TextField
                                label="Email"
                                onChange={(e) => handleInputs(e, 'email')}
                                size="small"
                                value={email}
                                helperText={formatoCorreo}
                                fullWidth
                                error={validarEmail}
                                variant="standard"/>
                        </div>
                    </Col>
                    <Col md="12">
                        <div style={{marginLeft: '20px', marginTop: '10px', marginRight: '20px', display: 'flex'}}>
                            <TextField
                                type={eye}
                                label="Contraseña"
                                fullWidth
                                value={password}
                                error={validarPassword}
                                onChange={(e) => handleInputs(e, 'password')}
                                variant="standard"
                                size="small"/>
                            <div style={{marginTop: '7px'}}>
                                {eye === 'password' ?
                                    <IconButton aria-label="delete" onClick={handdlePassword}>
                                        <Visibility/>
                                    </IconButton> :
                                    <IconButton aria-label="VisibilityOffIcon" onClick={handdlePassword}>
                                        <VisibilityOffIcon/>
                                    </IconButton>}

                            </div>
                        </div>
                    </Col>
                </Row>
                {alert === true ? <div style={{marginTop: '5%', marginLeft: '20px', marginRight: '20px'}}>
                    <Alert severity="error">
                        {texto}
                    </Alert>
                </div> : ''}
                {alert2 === true ? <div style={{marginTop: '5%', marginLeft: '20px', marginRight: '20px'}}>
                        <Alert severity="success" style={{fontSize:'17px'}}>
                            {texto}
                        </Alert>
                    </div>
                    : ''}
                {loading === false ? <div className="contenedorBoton ">
                        <Button id="styleButton" size="sm"  disabled={ocultarBtn} onClick={() => submit()}>INGRESAR</Button>
                    <div className="contenedorBoton mt-40 ">
                        <Button  style={{
                            background: 'transparent',
                            border: 'none',
                            color: '#fd601c'
                        }} id="pointer" onClick={() => handdleModal(1)}>
                            CREAR CUENTA
                        </Button>
                    </div>
                        <div className="contenedorBoton " style={{marginTop:'0px'}}>
                            <Button  style={{
                                background: 'transparent',
                                border: 'none',
                                color: 'grey'
                            }} id="pointer" onClick={() => handdleModal(0)}>
                                ¿OLVIDASTE O SE TE BLOQUEÓ TU CONTRASEÑA?
                            </Button>
                        </div>
                    </div>
                    : <div className="contenedorBoton "><LoadingButton
                        loading
                        loadingPosition="start"
                        size="small"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                    >
                        Cargando
                    </LoadingButton>
                    </div>
                }
            </Form>}
            {redirect ? <Redirect push to="/micuenta" />: null}
            {/*{redirectHome ? <Redirect push to="/" />: null}*/}

            <Modal isOpen={modal} toggle={() => handdleCerrarModal()}>
                <div style={{textAlign: 'end', margin: '13px'}}>
                    <IconButton aria-label="fingerprint" color="secondary" onClick={() => handdleCerrarModal()}>
                        <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                    </IconButton>
                </div>
                <ModalBody>
                    {idModal === 1 ? <RegistrarCuenta cerrarModal={()=>  props.cerrarModalLogin()} /> :
                        <OlvideContrasenia olvideContrasenia={() => setmodal(false)}/>}
                </ModalBody>

            </Modal>
        </div>
    );
})

export default Login
