import React, { useEffect} from 'react';
import {Button, Modal, ModalBody} from "reactstrap";
import "./style.css"
import RegistrarCuenta from "../Registrar_cuenta/registrarCuenta";
import Login from "../Login/login";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const InformacionDesafio = (props) => {
    const [data, setData] = React.useState(props.data);
    const [modal, setmodal] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [idModal, setidModal] = React.useState(0);
    const {infoUser} = useSelector(state => state.usuarios);
    const [token] = React.useState(localStorage.getItem('token_2'));
    const {estadoLogin} = useSelector(state => state.estadoLogin);

    const   handleModal = (idModal) => {
        setmodal(true)
        setidModal(idModal)
    }
    const  handleCerrarModal = (idModal) => {
        setmodal(false)
        setidModal(0)
    }
    const handlePlataforma = () => {
        let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`
        // console.log(infoUser.diasvigencia)
        if (infoUser.diasvigencia > 0 || infoUser.state === 1 ) {
            window.open(url, '_self', 'noopener,noreferrer')
        } else {
            setRedirect(true)
        }
    }

    useEffect(() => {
        document.getElementById("t").innerHTML = data.desc

    }, []);
    const boton = data.status === "ACTIVE" ? 'initial' : 'none'


    return (
        <div style={{
            whiteSpace: 'pre-wrap',
            padding: '45px',
        }}
             className="contendorInformacionDesafios">
            <div style={{textAlign: 'center'}}><h3 className="theme-color">{data.title}</h3></div>
            <div style={{marginTop: '10px', marginBottom: '10px'}}>
                <div className="row">
                    <div className="col-12" style={{display: 'flex'}}>
                        <div style={{alignSelf: 'center'}}>
                            <span style={{fontWeight: '800', fontSize: '1rem'}}>Patrocinado:</span>
                        </div>
                        <div className="styleImagenDesafios" >
                            <img src={data.sponsorPhoto} alt="App Landing"/>
                        </div>
                    </div>
                </div>
            </div>

            <div id="t">
            </div>

            <div > {estadoLogin ?  <div style={{marginTop:'46px', display:`${boton}`}}>
                <Button id="styleButton"
                    // disabled={data.status!== "ACTIVE"  }
                        onClick={() => handlePlataforma()}
                >SUBE TU SOLUCIÓN AQUÍ</Button>
            </div>:
                <div style={{marginTop:'46px', display:`${boton}`}}>
                    <Button id="styleButton"
                        // disabled={data.status!== "ACTIVE"  }
                            onClick={() => handleModal(0)}
                    >SUBE TU SOLUCIÓN AQUÍ</Button>
                </div>}

                <p className="theme-color" style={{fontWeight:'700', fontSize:'15px'}}>* Sin costo para las startups</p>
            </div>

            <Modal isOpen={modal} toggle={handleCerrarModal}>
                <div style={{textAlign: 'end', margin: '13px'}}>
                    <IconButton aria-label="fingerprint" color="secondary" onClick={() =>setmodal(false)}>
                        <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                    </IconButton>
                </div>
                <ModalBody>
                    {idModal === 1 ? <RegistrarCuenta abrirModal={() => setidModal(true)}
                                                      cerrarModal={() => setidModal(false)}/> :
                        <Login/>
                    }
                </ModalBody>
            </Modal>
            {redirect ? (<Redirect push to="/micuenta"/>) : null}

        </div>
    );
};

export default InformacionDesafio;