import React, {Component} from "react";
import imagen1 from '../assets/images/servicios/FACILITA-LA-COLABORACIÓN.png'
import imagen2 from '../assets/images/servicios/VITRINA-DE-INNOVACIÓN.PNG'
import imagen3 from '../assets/images/servicios/DESCUBRE-SOLUCIONES.png'


class Service extends Component {
    render() {
        let data = [
            {
                icon: imagen2,
                class: "zoom-out-right",
                title: 'VITRINA DE INNOVACIÓN Y TECNOLOGÍAS',
                desc: 'Promueve o encuentra soluciones tecnológicas en nuestra vitrina virtual'
            },

            {
                icon: imagen1,
                class: "zoom-out-down",
                title: 'FACILITA LA COLABORACIÓN',
                desc: 'Conexión real y permanente entre tecnologías y nuevas oportunidades de negocio'
            },

            {
                icon: imagen3,
                class: "zoom-out-left",
                title: 'DESCUBRE OPORTUNIDADES REALES DE NEGOCIO',
                desc: 'Conéctate con la región digitalmente'
            }
        ];
        let DataList = data.map((val, i) => {
            return (
                <div className="col-lg-4 service-column" key={i} data-aos={val.class} data-aos-offset="200"
                     data-aos-delay="50"
                     data-aos-duration="1000">
                    <div className="single-service text-center">
                        <div className="service-icon">
                            <div style={{marginTop: '10px'}}>
                                <img src={val.icon} width={67} height={68} alt="icon"/></div>
                        </div>
                        <h4 className="title">{val.title}</h4>
                        <div style={{    lineHeight: '21px',
                            fontSize: '18px'}}>
                            <p className="desc">{val.desc}</p>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div>
                {/* Start Service Area */}
                <div className={`service-area ${this.props.horizontal}`}>
                    <div className="container">
                        <div className="row">
                            {DataList}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}
            </div>
        )
    }
}

export default Service;

