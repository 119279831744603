import axios from "axios";
import React, {useState} from 'react';
import logoInnova from "../../assets/images/logo/Logo InnovaConnect.png";
import {Col, Row, Button, Modal, ModalBody, Form} from "reactstrap";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import {Redirect} from "react-router-dom";
import {validarToken} from "../utils/token";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";

const StartupForm = React.memo((props) => {
    const [modal, setmodal] = useState(props.modalito);
    const [ocultarBtn, setOcultarBtn] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const {infoUser} = useSelector(state => state.usuarios);
    const [nombre, setnombre] = useState('');
    const [apellido, setapellido] = useState('');
    const [web, setweb] = useState('');
    const [validarNombre, setvalidarNombre] = useState(false);
    const [validarApellido, setvalidarApellido] = useState(false);
    const [validarWeb, setvalidarWeb] = useState(false);
    const [alert, setalert] = useState(false);
    const [texto, settexto] = useState(false);
    const [error, seterror] = useState(false);
    //const navigate = Redirect();

    function handdleModal (id) {
        setmodal(true)
        setIdModal(id)
        // console.log('soy', modal)
    }
    const handdleCerrarModal=()=>{
        setmodal(false)
        //navigate("/micuenta");
        //setIdModal(5)
    }

    const handleInputs = (event, inp) => {
        switch (inp) {
            case 'nombre':
                setnombre(event.target.value)
                break;
            case 'apellido':
                setapellido(event.target.value)
                break;
            case 'web':
                setweb(event.target.value)
                break;
            default:
                break;
        }
    }

    const validar = () => {
        let final = false
        if (nombre.length === 0 || apellido.length === 0) {
            if (nombre.length === 0) {
                setvalidarNombre(true)
            } else {
                setvalidarNombre(false)
            }
            if (apellido.length === 0) {
                setvalidarApellido(true)
            } else {
                setvalidarApellido(false)
            }
            if (web.length === 0) {
                setvalidarWeb(true)
            } else {
                setvalidarWeb(false)
            }
            seterror(true)
            setalert(true)
            settexto('Rellenar todos los campos')

            final = false;
        } else {
            seterror(false)
            setalert(false)
            final = true
        }
        return final
    }

    const handlePlataforma =  async () => {
        let token = localStorage.getItem('token_2')
        let token2 = localStorage.getItem('token_3')
        let url = `${process.env.REACT_APP_API_I360}/login?token=${token}`
        await axios.post(`${process.env.REACT_APP_API_STREAMING}/datosuser`, {
            email: localStorage.getItem('email')
        }, {headers: {authorization: token2}})
            .then((response) => {
                if (infoUser.diasvigencia > 0 || infoUser.state === 1) {
                    if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                        console.log('TOKEN VALIDO, REDIRECCIONANDO...')
                    }
                    window.open(url, '_self', 'noopener,noreferrer')
                } else {
                    setRedirect(true)
                }
            }).catch(error => {
                if( validarToken(error)) {
                    if (`${process.env.REACT_APP_ENTORNO}` === 'develop') {
                        console.log('TOKEN, INVALIDO DESLOGUEANDO...')
                    }
                }
                console.log('ERROR :', error.response.data.error)
            })
    }

    const submit = async () => {
        const valida = validar()
        const token2 =  localStorage.getItem('token_3')
        if (valida) {
            await axios.post(`${process.env.REACT_APP_API_STREAMING}/soyStartup`, {
                sitioWeb_startup: web,
                nombre: nombre,
                apellido: apellido,
                correo: infoUser.email,
            },{headers:{authorization: token2}})
            .then(r =>
                {
                    props.cerrarModal()
                    Swal.fire({
                        icon: 'success',
                        text: r.data.mensaje
                    })
                })
            .catch(e => console.log(e))
        }
    }

    return (
        <div>
            {localStorage.getItem('dispositivo') === 'mobile' ? '' :
                <div style={{textAlign: 'center'}}>
                    <h3 className="theme-color ">FORMULARIO PLAN GRATUITO STARTUPS</h3>
                </div>
            }
            <div style={{width: '100%', height: '30%', textAlign: 'center'}}>
                <img src={logoInnova} width={170} height={115} alt="logo innova"/>
            </div>
            <br/>

            <div>
                <div style={{marginLeft: '20px', marginRight: '20px'}}>
                    <TextField
                        label="Correo"
                        size="small"
                        value={infoUser.email}
                        fullWidth
                        disabled={true}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px'}}>
                    <TextField
                        label="Sitioweb startup"
                        onChange={(e) => handleInputs(e, 'web')}
                        size="small"
                        value={web}
                        error={validarWeb}
                        fullWidth
                        variant="standard"/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px'}}>
                    <TextField
                        label="Nombre"
                        onChange={(e) => handleInputs(e, 'nombre')}
                        size="small"
                        value={nombre}
                        error={validarNombre}
                        fullWidth
                        variant="standard"/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px', marginTop: '10px'}}>
                    <TextField
                        label="Apellido"
                        onChange={(e) => handleInputs(e, 'apellido')}
                        size="small"
                        value={apellido}
                        error={validarApellido}
                        fullWidth
                        variant="standard"/>
                </div>
            </div>
            {alert === true ? <div style={{marginTop: '5%', marginLeft: '20px', marginRight: '20px'}}>
                    <Alert severity="error">
                        {texto}
                    </Alert>
                </div> : ''}
            <div className="contenedorBoton ">
                <Row>
                    <Col md={6} style={{justifyContent:'start'}}>
                    </Col>
                    <Col md={6} style={{justifyContent:'end'}}>
                        <Button id="styleButton" size="md"  disabled={ocultarBtn} onClick={() => submit()}>Enviar</Button>
                    </Col>
                </Row> 
            </div>
            {redirect ? <Redirect push to="/micuenta" />: null}
            {/*{redirectHome ? <Redirect push to="/" />: null}*/}

            <Modal isOpen={modal} toggle={() => handdleCerrarModal()}>
                <div style={{textAlign: 'end', margin: '13px'}}>
                    <IconButton aria-label="fingerprint" color="secondary" onClick={() => handdleCerrarModal()}>
                        <CloseIcon style={{color: 'grey',}} fontSize={"medium"}/>
                    </IconButton>
                </div>
                <ModalBody>
                </ModalBody>

            </Modal>
        </div>
    );
})

export default StartupForm